<script>
import { RouterView } from 'vue-router';


export default {
  name: 'App',
  components: {
    RouterView,
  }
}

</script>
<template>
  <div>
    <RouterView />
  </div>
</template>
<style>

#app {
  text-align: center;
}
</style>
